<template>
  <div>
    <div class="invite-coupon-bg">
      <div class="invite-title">品质服务还不贵，首单最高减$10</div>
      <div class="invite-desc">$100礼包已到账</div>
      <div class="invite-tags">
        <div class="invite-tag">专职司机</div>
        <div class="invite-tag">安全出行</div>
        <div class="invite-tag">品质服务</div>
      </div>

      <div class="invite-pop">
        <div class="invite-pop-box">
          <div class="invite-pop-box-wapper">
            <div class="invite-old-title">新人大礼包已放入136****2637账户</div>
            <div class="invite-new-couponimg text-center">
              <img src="../../assets/new_user_coupon.png"
                   alt="">
            </div>
            <div class="invite-new-advantage">
              <div class="invite-new-advantage-line"></div>
              <div class="invite-new-advantage-title">浣熊出行独家优势</div>
              <div class="invite-new-advantage-line"></div>
            </div>
            <div class="invite-new-advantage-list">
              <div class="invite-new-advantage-item">
                <div class="invite-new-advantage-item-title">优质评价</div>
                <div class="invite-new-advantage-item-desc">高峰、下雨天不溢价，平峰期更有机会享受动态折扣</div>
              </div>
              <div class="invite-new-advantage-item">
                <div class="invite-new-advantage-item-title">专注品质</div>
                <div class="invite-new-advantage-item-desc">全面导入司机培训认证体系</div>
              </div>
              <div class="invite-new-advantage-item">
                <div class="invite-new-advantage-item-title">安全舒适</div>
                <div class="invite-new-advantage-item-desc">乘客保险总额$300万</div>
              </div>
              <div class="invite-new-advantage-item">
                <div class="invite-new-advantage-item-title">新人有礼</div>
                <div class="invite-new-advantage-item-desc">首次注册，赠送价值$100新人礼包 <br>（同一手机、设备号仅可享受一次）</div>
              </div>
            </div>
          </div>
          <div class="usecoupon-rightnow">
            <div class="usecoupon-rightnow-btn"
                 @click="useCoupon">
              立即享受优惠
            </div>

          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'newuser',
  methods: {
    useCoupon () {
      this.$router.push('/oauth')
    }
  }

}
</script>

<style lang="less" scoped>
@import '../../styles/mixin.less';

.invite {
  &-coupon {
    &-bg {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: url('../../assets/coupon_bg.png') 0 0 no-repeat;
      background-size: cover;
    }
  }

  &-title {
    margin: 80px 60px 10px 0;
    height: 42px;
    font-size: 30px;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    color: rgba(36, 133, 145, 1);
    line-height: 42px;
    text-align: right;
  }

  &-desc {
    margin: 10px 60px 10px 0;
    height: 80px;
    font-size: 56px;
    font-family: 'PingFangSC-Semibold';
    font-weight: 600;
    color: rgba(36, 133, 145, 1);
    line-height: 80px;
    text-align: right;
  }

  &-tags {
    margin: 20px 60px 0px auto;
    display: flex;
    width: 350px;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }
  &-tag {
    width: 110px;
    height: 40px;
    background: rgba(36, 133, 145, 1);
    border-radius: 30px;
    font-size: 20px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 40px;
    text-align: center;
  }

  &-pop {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;

    &-box {
      // padding-bottom: 170px;
      position: relative;
      width: 690px;
      height: 920px;
      margin: 0 auto;
      padding: 40px 0 0px;
      background: rgba(255, 255, 255, 1);
      border-radius: 20px;
      box-sizing: border-box;
      // overflow: auto;
      // -webkit-overflow-scrolling: touch;

      &-wapper {
        // width: 690px;
        height: 100%;
        padding-bottom: 150px;
        margin: 0 auto;
        // padding: 40px 0 60px;
        // background: rgba(255, 255, 255, 1);
        // border-radius: 20px;
        box-sizing: border-box;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
      }
    }
  }

  &-old {
    text-align: center;

    &-title {
      margin-bottom: 10px;
      font-size: 32px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 44px;
      text-align: center;
    }

    &-des {
      font-size: 26px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 36px;
      text-align: center;
    }

    &-btn {
      width: 440px;
      height: 90px;
      margin: 40px auto;
      background: rgba(12, 170, 50, 1);
      box-shadow: 0px 8px 22px 0px rgba(12, 170, 50, 0.4);
      border-radius: 44px;
      font-size: 30px;
      font-family: 'PingFangSC-Medium';
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 90px;
      text-align: center;
    }

    &-rule {
      font-size: 20px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: rgba(12, 170, 50, 1);
      line-height: 28px;
      text-align: center;
    }
  }

  &-new {
    &-couponimg {
      .size(312px, 246px);
      margin: 40px auto;
      img {
        width: 100%;
      }
    }

    &-advantage {
      display: flex;
      padding: 0 40px;
      margin-bottom: 40px;
      flex: 1;
      align-items: center;
      justify-content: space-between;

      &-line {
        width: 152px;
        height: 2px;
        background: rgba(238, 238, 238, 1);
      }

      &-title {
        font-size: 30px;
        font-family: 'PingFangSC-Regular';
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 42px;
      }

      &-list {
        padding: 0 40px;
      }

      &-item {
        margin-bottom: 28px;
        padding-bottom: 30px;
        border-bottom: 2px dashed rgba(238, 238, 238, 1);

        &-title {
          margin-bottom: 10px;
          font-size: 30px;
          font-family: 'PingFangSC-Regular';
          font-weight: 400;
          color: rgba(12, 170, 50, 1);
          line-height: 42px;
        }

        &-desc {
          font-size: 24px;
          font-family: 'PingFangSC-Regular';
          font-weight: 400;
          color: rgba(153, 153, 153, 1);
          line-height: 34px;
        }
      }
    }
  }
}

.usecoupon-rightnow {
  display: flex;
  align-items: center;
  position: absolute;
  height: 170px;
  padding: 0 40px;
  bottom: 0px;
  left: 0;
  right: 0;
  // background: transparent;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 20px;
  filter: blur(0px);

  &-btn {
    width: 560px;
    height: 90px;
    margin: 0 auto;
    background: rgba(12, 170, 50, 1);
    box-shadow: 0px 8px 22px 0px rgba(12, 170, 50, 0.4);
    border-radius: 44px;
    font-size: 30px;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 90px;
    .text-center;
  }
}
</style>